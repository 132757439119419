import React from 'react';
import {useState} from 'react';
import WechatIcon from '../../public/assets/wechat.svg';
import VisibilityOnIcon from '../../public/assets/visibility_on.svg';
import VisibilityOffIcon from '../../public/assets/visibility_off.svg';
import CloseIcon from '../../public/assets/close.svg';
import { useRecoilState } from 'recoil';
import { loginWindowAtom, registerWindowAtom } from '../recoil/atoms/atoms';


const RegisterWindow: React.FC = () => {
  // 定义一个状态来控制密码的显示状态
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginWindowStatus, setLoginWindowStatus] = useRecoilState(loginWindowAtom);
  const [registerWindowStatus, setRegisterWindowStatus] = useRecoilState(registerWindowAtom);
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);

  return (
    <div className="relative w-96 h-128 bg-white rounded-[16px] py-10 px-10 flex flex-col items-center z-[99]">
      <button
        onClick={() => {
          setLoginWindowStatus(false);
          setRegisterWindowStatus(false);
        }}
        className="hover:bg-gray-100 rounded-full p-2 absolute top-4 right-4"
      >
        <img src={CloseIcon} alt="close" className="size-6" />
      </button>

      <div className="flex items-center justify-center w-full gap-6 px-2 mt-4 text-xl">
        <span className="text-primary-color font-bold">注册</span>
      </div>

      <div className="w-full bg-gray-100 rounded-full px-4 py-3 mt-12">
        <input
          className="focus:outline-none bg-transparent"
          type="text"
          placeholder="用户名"
        />
      </div>

      <div className="w-full bg-gray-100 rounded-full px-4 py-3 flex items-center mt-6">
        <input
          className="focus:outline-none bg-transparent flex-grow"
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder="密码"
        />
        <button
          className="ml-2"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <img
            src={isPasswordVisible ? VisibilityOffIcon : VisibilityOnIcon}
            alt="visibility"
            className="size-6"
          />
        </button>
      </div>

      <div className="w-full flex justify-between mt-2">
        <button onClick={() => {
          setLoginWindowStatus(true);
          setRegisterWindowStatus(false);
        }} className="text-sm underline underline-offset-2 text-gray-400 hover:text-gray-500">
          已有账号?
        </button>
        <button className="text-sm underline underline-offset-2 text-gray-400 hover:text-gray-500">
          忘记密码
        </button>
      </div>

      <button className="w-full bg-primary-color/90 hover:bg-primary-color text-white font-bold rounded-full py-2 mt-8">
        注册
      </button>

      <div className="flex items-center justify-center w-full gap-2 px-2 mt-6">
        <hr className="flex-grow border-gray-100 border" />
        <span className="text-sm text-gray-300">或者</span>
        <hr className="flex-grow border-gray-100 border" />
      </div>

      <div className="flex items-center justify-center w-full mt-4">
        <button className="bg-gray-100 rounded-full p-2">
          <img src={WechatIcon} alt="wechat" className="size-6" />
        </button>
      </div>
    </div>
    );
};

export default RegisterWindow;
