import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {leftSideBarAtom} from '../../recoil/atoms/atoms';
import {useRecoilState} from 'recoil';

const RightSideBar: React.FC = () => {
  return <div className="w-1/4 border-l-2 bg-gray-100 h-full mr-24">asd</div>;
};

export default RightSideBar;
