import React, {useEffect, useRef, useState} from 'react';
import SessionItem from '../components/MsgPage/SessionItem';
import {
  MsgItemType,
  SessionItemType,
  UserType,
} from '../components/MsgPage/MsgClasses';
import ImageIcon from '../../public/assets/image.svg';
import EmojiIcon from '../../public/assets/emoji.svg';
import SettingIcon from '../../public/assets/settings.svg';
import avatar from '../../public/assets/avatar.png';

const MsgPage: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);

  const [msg, setMsg] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [sessionList, setSessionList] = useState<SessionItemType[]>([
    {
      userId: 1,
      avatar: avatar,
      name: '小刘1',
      msg: '你好',
      time: '2024-01-01',
      onClick: () => {},
      focus: false,
    },
    {
      userId: 2,
      avatar: avatar,
      name: '小刘2',
      msg: '你好',
      time: '2024-01-01',
      onClick: () => {},
      focus: false,
    },
  ]);

  const removeItem = (userId: number) => {
    setSessionList(sessionList.filter(item => item.userId !== userId));
  };

  const moveToTop = (userId: number | undefined) => {
    if (userId === undefined) return;
    const item = sessionList.find(item => item.userId === userId);
    if (item) {
      setSessionList([
        item,
        ...sessionList.filter(item => item.userId !== userId),
      ]);
    }
  };

  const addItem = (item: SessionItemType) => {
    setSessionList([item, ...sessionList]);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // 重设高度，防止内容减少时高度不减小
      textarea.style.height = 'auto';
      // 设置新的高度，最小为单行，最大不超过 max-height
      textarea.style.height = `${Math.min(textarea.scrollHeight, 60)}px`; // 200px 是最大高度
    }
  }, [msg]);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <main className="w-full flex h-5/6 border-y">
        {/* 左边栏 */}
        <div className="flex flex-col w-1/5 border-r">
          <div className="w-full h-16 flex items-center justify-center">
            <span className="text-xl font-bold">全部消息</span>
          </div>
          <div className="w-full flex-grow">
            {sessionList.map(item => (
              <div
                onClick={() => {
                  setCurrentUser({
                    userId: item.userId,
                    avatar: item.avatar,
                    name: item.name,
                  });
                }}
                className="border-y"
                key={item.userId}
              >
                <SessionItem {...item} />
              </div>
            ))}
          </div>
        </div>
        {/* 右边栏 */}
        <div className="flex flex-col w-4/5">
          <header className="w-full h-16 flex justify-center items-center">
            <span className="text-xl font-bold">
              {currentUser ? currentUser.name : 'unkown user'}
            </span>
          </header>

          <div className="w-full flex-grow border-y" />

          <footer className="w-full h-auto flex items-center px-4 py-2 gap-2">
            <img src={EmojiIcon} alt="emoji" className="size-8" />
            <img src={ImageIcon} alt="image" className="size-8" />
            <img src={SettingIcon} alt="setting" className="size-8" />
            <div className="flex-grow rounded-lg border items-center justify-center flex px-4 py-2">
              <textarea
                ref={textareaRef}
                placeholder="发消息..."
                rows={1}
                style={{maxHeight: '60px', overflowY: 'auto'}}
                value={msg}
                onChange={e => setMsg(e.target.value)}
                className="w-full h-auto focus:outline-none resize-none"
              />
            </div>
            <button
              onClick={() => {
                moveToTop(currentUser ? currentUser.userId : 0);
              }}
              className="w-20 h-10 bg-primary-color text-white font-bold rounded-full"
            >
              发送
            </button>
          </footer>
        </div>
      </main>
    </div>
  );
};

export default MsgPage;