import React from 'react';


const NotificationPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center h-full">
    </div>
  );
};

export default NotificationPage;
