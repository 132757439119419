import React, {useEffect, useState} from 'react';
import logo from '../../../public/assets/logo.png';
import UnfocusHomeIcon from '../../../public/assets/HomeFull1.svg';
import FocusHomeIcon from '../../../public/assets/HomeFull0.svg';
import UnfocusMsgIcon from '../../../public/assets/MsgFull0.svg';
import FocusMsgIcon from '../../../public/assets/MsgFull1.svg';
import FocusProfileIcon from '../../../public/assets/profileFull1.svg';
import UnfocusProfileIcon from '../../../public/assets/profileFull0.svg';
import FocusEditIcon from '../../../public/assets/EditFull1.svg';
import UnfocusEditIcon from '../../../public/assets/EditFull0.svg';
import FocusNotificationIcon from '../../../public/assets/NotificationFull1.svg';
import UnfocusNotificationIcon from '../../../public/assets/NotificationFull0.svg';
import FocusIntroIcon from '../../../public/assets/IntroFull1.svg';
import UnfocusIntroIcon from '../../../public/assets/IntroFull0.svg';
import FocusMoreIcon from '../../../public/assets/MoreFull1.svg';
import UnfocusMoreIcon from '../../../public/assets/MoreFull0.svg';
import moreIcon from '../../../public/assets/more.svg';
import {Link, useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {loginWindowAtom, registerWindowAtom, userAtom} from '../../recoil/atoms/atoms';

const SideBarItem: React.FC<{
  tag: string;
  unFocusedIcon: string;
  focusedIcon: string;
  text: string;
  link: string;
}> = ({tag, unFocusedIcon, focusedIcon, text, link}) => {
  const currentPath = useLocation();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (currentPath.pathname.split('/')[1] === tag) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [currentPath.pathname]);

  return (
    <div className="w-full">
      <Link to={link}>
        <button className="flex w-full justify-start items-center pl-6 gap-4 hover:bg-gray-100 rounded-full py-2">
          <img
            src={isFocused ? focusedIcon : unFocusedIcon}
            alt={text}
            className="size-8"
          />
          <span
            className={`text-xl ${isFocused ? 'font-bold' : 'font-normal'}`}
          >
            {text}
          </span>
        </button>
      </Link>
    </div>
  );
};

const LeftSideBar: React.FC = () => {
  const [userAtomState, setUserAtomState] = useRecoilState(userAtom);

  const [loginWindowStatus, setLoginWindowStatus] = useRecoilState(loginWindowAtom);
  const [registerWindowStatus, setRegisterWindowStatus] = useRecoilState(registerWindowAtom);

  return (
    <div className="fixed flex flex-col items-center h-full w-72 border-r pt-4 pb-8 bg-white">
      <div>
        <img src={logo} alt="logo" className="size-16" />
      </div>
      <div className="flex flex-col w-full px-8 gap-2 mt-4">
        <SideBarItem
          unFocusedIcon={UnfocusHomeIcon}
          focusedIcon={FocusHomeIcon}
          tag="home"
          text="首页"
          link="/home"
        />
        <SideBarItem
          unFocusedIcon={UnfocusNotificationIcon}
          focusedIcon={FocusNotificationIcon}
          tag="notification"
          text="通知"
          link="/notification"
        />
        <SideBarItem
          unFocusedIcon={UnfocusMsgIcon}
          focusedIcon={FocusMsgIcon}
          tag="msg"
          text="消息"
          link="/msg"
        />
        <SideBarItem
          unFocusedIcon={UnfocusProfileIcon}
          focusedIcon={FocusProfileIcon}
          tag="userhome"
          text="个人"
          link="/userhome"
        />
        <SideBarItem
          unFocusedIcon={UnfocusEditIcon}
          focusedIcon={FocusEditIcon}
          tag="edit"
          text="发布"
          link="/edit"
        />
        <SideBarItem
          unFocusedIcon={UnfocusIntroIcon}
          focusedIcon={FocusIntroIcon}
          tag="intro"
          text="新人"
          link="/intro"
        />
        <SideBarItem
          unFocusedIcon={UnfocusMoreIcon}
          focusedIcon={FocusMoreIcon}
          tag="more"
          text="更多"
          link="/more"
        />
      </div>

      <div className="flex-grow" />

      <div>
        {userAtomState.loggedIn ? (
          <div className="flex items-center gap-2">
            <img src={userAtomState.avatarUrl} />
            <span className="font-bold">{userAtomState.userName}</span>
            <div className="flex-grow" />
            <div onClick={() => {}} className="hover:bg-gray-200 rounded-full">
              <img src={moreIcon} className="size-8" />
            </div>
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              onClick={() => {
                setLoginWindowStatus(true);
              }}
              className="w-20 h-10 bg-primary text-white hover:bg-primary/80 font-bold rounded-full"
            >
              登录
            </button>
            <button
              onClick={() => setRegisterWindowStatus(true)}
              className="w-20 h-10 text-primary font-bold hover:bg-gray-200 rounded-full"
            >
              注册
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftSideBar;
