import {createRoot} from 'react-dom/client';
import {App} from './App';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import React from 'react';

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
const url: string = process.env.REACT_APP_API_URL + '/graphql';

const client = new ApolloClient({
  uri: url, // 替换为你的 GraphQL API 端点
  cache: new InMemoryCache(),
});

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
);
