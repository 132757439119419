import React, {useEffect, useState, useCallback} from 'react';
// import Editor from '../components/EditorPage/Editor';
import NewEditor from '../components/EditorPage/NewEditor';


const SectionBar: React.FC<{
  selectedType: string;
  setSelectedType: (type: string) => void;
}> = ({selectedType, setSelectedType}) => {

  return (
    <div className="w-full flex border-b text-lg">
      <button className={`h-10 py-2 px-3 text-center bg-white`}
        onClick={() => setSelectedType('文本')}
      >
        <span className={`${selectedType === '文本' ? 'border-b-2 pb-2 border-primary font-bold text-primary' : 'text-gray-300'}`}>文本帖</span>
      </button>
      <button className={`h-10 py-2 px-3 text-center bg-white`}
        onClick={() => setSelectedType('图文')}
      >
        <span className={`${selectedType === '图文' ? 'border-b-2 pb-2 border-primary font-bold text-primary' : 'text-gray-300'}`}>图文帖</span>
      </button>
    </div>
  );
};

const EditorPage: React.FC = () => {
  /*undo redo clear heading bold italic underline list quote divider image link 表格 */

  const [selectedType, setSelectedType] = useState('文本');

  return (
    <div className="flex justify-between h-auto min-h-screen w-full gap-12">
      <div className="min-w-80 h-auto flex-grow flex-1 bg-gray-100"/>

      <div className="flex flex-col mt-20 items-center gap-2">
        <SectionBar selectedType={selectedType} setSelectedType={setSelectedType} />

        <main className="w-[700px] h-auto flex flex-col items-center">
          <NewEditor/>
        </main>
      </div>

      <div className="min-w-80 h-auto flex flex-col items-start bg-gray-100 flex-grow flex-1"/>
    </div>
  );
};

export default EditorPage;