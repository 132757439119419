import React, { useState, useRef } from 'react';
import OrangeSpot from '../components/LandingPage/OrangeSpot';
import {useQuery, gql} from '@apollo/client';
import LogoIcon from '../../public/assets/logo.png';
import HomePageImage1 from '../../public/assets/exhibition1.png';
import HomePageImage2 from '../../public/assets/exhibition2.png';
import IconOne from '../../public/assets/icon1.png';
import IconTwo from '../../public/assets/icon2.jpg';
import IconThree from '../../public/assets/icon3.jpg';
import IconFour from '../../public/assets/icon4.png';
import IconFive from '../../public/assets/icon5.png';
import { useNavigate } from 'react-router-dom';
import FunctionImage1 from '../../public/assets/function1.png';
import FunctionImage2 from '../../public/assets/function2.png';
import FunctionImage3 from '../../public/assets/function3.png';
import BackgroundImage1 from '../../public/assets/bg1.png';
import BackgroundImage2 from '../../public/assets/bg2.png';
import BackgroundImage3 from '../../public/assets/bg3.png';

const ContactUsButton = () => {
    // const navigate = useNavigate();
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    return <button onClick={scrollToBottom} className="bg-primary-color text-white font-bold px-5 py-2 rounded-full hover:bg-primary-color/80 hover:scale-105 transition-all duration-300">联系我们</button>
}

const Logo = () => {
    const navigate = useNavigate();
    return <div onClick={() => navigate('/')} className='flex items-center cursor-pointer'>
        <img src={LogoIcon} alt="logo" className="size-12"/>
        <span className="ml-2 text-2xl font-bold text-primary-color">Ray<span className='text-black/80'>no AI</span></span>
    </div>
}

const AnsweredQuestion = (props: {question: string, answer: string}) => {
    const {question, answer} = props;
    const [showAnswer, setShowAnswer] = useState(false);
    const contentRef = useRef<HTMLSpanElement>(null);

    return(
        <div className='flex flex-col items-start bg-gray-300/40 py-6 px-12 rounded-[20] w-full'>
            <button className='text-xl w-full font-bold tracking-wide text-left' onClick={() => setShowAnswer(!showAnswer)}>{question}</button>
            <span ref={contentRef} className='tracking-wide mt-2 text-gray-500' style={{
                maxHeight: showAnswer ? contentRef.current?.scrollHeight : 0,
                overflow: 'hidden',
                transition: 'max-height 0.3s ease',
            }}>{answer}</span>
        </div>
    )
}

const AnalyseCard = (props: {title: string, content: string, image: string}) => {
    const {title, content, image} = props;
    return (
        <div className='w-full flex items-center rounded-2xl bg-white shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300'>
            <div className='w-2/5 flex items-center justify-center'>
                <img src={image} alt="analyse" className='w-full max-h-72 rounded-l-2xl' 
                style={{maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))', WebkitMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'}}/>
            </div>
            <div className='w-1/2 flex flex-col items-start pt-8 pb-12 px-12'>
                <span className='text-xl font-bold tracking-wide'>{title}</span>
                <span className='tracking-wide mt-2 text-gray-500'>{content}</span>
            </div>
        </div>
    )
}


const FeatureCard = (props: {title: string, content: string, image: string, left: boolean}) => {
    const {title, content, image, left} = props;

    if(left){
        return (
            <div className='flex py-6 px-12 w-4/5'>
                <div className='w-1/2 flex items-center justify-center z-[98]'>
                    <img src={image} alt="feature" className='shadow-md max-h-80 rounded-2xl w-auto'/>
                </div>
                <div className='w-1/2 flex flex-col items-center justify-center -ml-12 my-8 px-12 bg-white rounded-r-2xl shadow-sm'>
                    <span className='text-xl w-full font-bold tracking-wide text-left tracking-wider'>{title}</span>
                    <span className='tracking-wide w-full mt-4 tracking-wide text-gray-500 leading-relaxed'>{content}</span>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className='flex py-6 px-12 w-4/5'>
            
                <div className='w-1/2 flex flex-col items-start justify-center px-12 -mr-12 my-8 bg-white rounded-l-2xl shadow-sm'>
                    <span className='text-xl w-full font-bold text-left tracking-wider'>{title}</span>
                    <span className='tracking-wide w-full mt-4 tracking-wide text-gray-500 leading-relaxed'>{content}</span>
                </div>
                <div className='w-1/2 flex flex-col items-center'>
                    <img src={image} alt="feature" className='shadow-md rounded-2xl max-h-80 w-auto'/>
                </div>
            </div>
        )
    }

}

const NewLandingPage: React.FC = () => {

    const faqs = [
        {question: "暂无常见问题", answer: "欢迎留下您的宝贵意见！"},
    ]

    const featureCards = [
        {title: "语义检索取代传统条件筛选", content: "使用机器学习及NLP技术将用户任意查询请求转化为可执行的机器任务，并对返回结果进行相关性分析，实现最大的用户使用自由度。", image: FunctionImage1, left: true},
        {title: "LLM驱动智能体作为中间人，评估用户间吸引力，排除潜在不匹配点", content: "作为主动方，智能体可以提前发起虚拟沟通，判断对方需求。作为被动方，智能体能够对来访用户进行初步筛选，排除潜在不匹配点。整个过程基于数字档案，最大可能提高联系成功率。", image: FunctionImage2, left: false},
        {title: "AI智能体汇总用户数据，生成数字档案", content: "用户填写的资料、发布的内容、互动记录、互评内容、线下社交的反馈等数据将被汇总，形成用户的个人数字档案供AI分析使用。", image: FunctionImage3, left: true},
    ]

    const analyseCards = [
        {title: "传统Dating App", content: "用户数量扩张之后，运营能力不足或是商业化压力造成社群质量下降。另一方面，用户对社交质量的要求提高，传统基于条件筛选的社交模式难以满足用户需求。", image: BackgroundImage1, left: true},
        {title: "公开社交平台", content: "以广泛的社交网络为主，但是匹配效率更低，缺少对特定社交形式的功能支持，且缺乏对特殊用户身份隐私的保护。", image: BackgroundImage2, left: false},
        {title: "线下婚恋会员服务", content: "以线下一对一咨询顾问形式为主，这样可以节约时间精力但是服务费用较高，且对于企业来说，难以规模化。", image: BackgroundImage3, left: true}
    ]

    const introRef = useRef(null);
    const usageRef = useRef(null);
    const analyseRef = useRef(null);
    const faqRef = useRef(null);
    // const pricingRef = useRef(null);
    const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

  return (
    <div className="flex flex-col items-center w-full h-full bg-primary-color/5">

        <header className="w-full h-20 px-36 flex items-center justify-between fixed top-0 bg-white shadow-sm z-[99]">
            <Logo/>
            <div className='flex items-center justify-between'>
            <button className="mr-12 text-black/80 hover:text-black/60 hover:scale-105 transition-all duration-300" onClick={() => scrollToSection(introRef)}>功能介绍</button>
            <button className="mr-12 text-black/80 hover:text-black/60 hover:scale-105 transition-all duration-300" onClick={() => scrollToSection(usageRef)}>使用指南</button>
            <button className="mr-12 text-black/80 hover:text-black/60 hover:scale-105 transition-all duration-300" onClick={() => scrollToSection(analyseRef)}>竞品分析</button>
            {/* <button className="mr-12 text-black/80 hover:text-black/60 hover:scale-105 transition-all duration-300" onClick={() => scrollToSection(pricingRef)}>Pricing</button> */}
            <button className="text-black/80 hover:text-black/60 hover:scale-105 transition-all duration-300" onClick={() => scrollToSection(faqRef)}>FAQ</button>
            </div>
            <ContactUsButton/>
        </header>

        {/* 开头描述 */}
        <div className="w-full h-full flex flex-col items-center pt-60 pb-36">
            <span className="text-4xl font-bold mb-8 tracking-wider">
                贴身
                <span className="text-5xl mx-2 underline underline-offset- text-primary-color">AI</span>
                社交顾问，助您掌握每一个互动时刻
            </span>
            <span className="text-lg mb-16 tracking-wide">
                我们致力于将AI技术应用于各种传统社交场景，为各类用户提供更加智能、便捷、人性化的社交体验。
            </span>
            <ContactUsButton/>
            <div className='w-1/2 relative mb-96'>
                <img src={HomePageImage1} alt="home page" className='absolute mt-16 w-full shadow-md rounded-lg z-[50]'/>
                <img src={HomePageImage2} alt="home page" className='absolute mt-8 -ml-8 w-full shadow-md rounded-lg z-[49]'/>
            </div>

        </div>

        {/* built by engineers from xxx */}
        <div className="w-full border-t border-b border-grey bg-white shadow-lg flex flex-col items-center py-12">
            <span className='text-xl tracking-wide'>创始人团队来自</span>
            <div className='flex items-center gap-12 mt-4'>
                <img src={IconOne} alt="icon1" className="h-20 hover:scale-110 transition-all duration-300"/>
                <img src={IconTwo} alt="icon2" className="h-20 hover:scale-110 transition-all duration-300"/>
                <img src={IconThree} alt="icon3" className="h-20 hover:scale-110 transition-all duration-300"/>
                <img src={IconFour} alt="icon4" className="h-16 hover:scale-110 transition-all duration-300"/>
                <img src={IconFive} alt="icon5" className="h-20 hover:scale-110 transition-all duration-300"/>
            </div>
        </div>

        {/* 功能介绍 */}
        <div ref={introRef} className="w-full flex flex-col items-center pt-36 pb-24">
            <span className="text-3xl font-bold tracking-wider">核心功能</span>
            <span className='text-lg tracking-wide mt-8'>
                我们主要尝试优化的社交环节经过对已有社交平台的分析和用户反馈调研得出：
            </span>
            <div className='w-full flex flex-col items-center gap-8 mt-24'>
                {featureCards.map((card) => (
                    <FeatureCard title={card.title} content={card.content} image={card.image} left={card.left}/>
                ))}
            </div>
        </div>

        {/* 系统如何工作 */}
        <div ref={usageRef} className="w-full  flex flex-col items-center bg-primary-color/90 pt-36 pb-24 shadow-lg">
            <span className="text-3xl font-bold text-white tracking-wider">新人如何开始？</span>
            <span className='text-lg tracking-wide mt-8 text-white'>
                敬请期待...
            </span>
        </div>

        {/* 竞品分析 */}
        <div ref={analyseRef} className="w-full flex flex-col items-center bg-primary-color/10 pt-36 pb-24">
            <span className="text-3xl font-bold tracking-wider">竞品分析</span>
            <span className='text-lg tracking-wide mt-8'>
                早期选定的是🌈lgbt群体作为目标用户，我们作出如下竞争分析：
            </span>
            <div className='w-2/3 flex flex-col items-center gap-12 mt-20'>
                {analyseCards.map((card) => (
                    <AnalyseCard title={card.title} content={card.content} image={card.image}/>
                ))}
            </div>
        </div>


        {/* 收费模式 */}
        {/* <div ref={pricingRef} className="w-full flex flex-col items-center pt-36 pb-24">
            <span className="text-3xl font-bold tracking-wider">会员服务</span>
            <span className='text-lg tracking-wide mt-8'>
                可以从以下选择合适的会员服务。
            </span>
            <div className='w-2/3 flex items-center justify-between mt-20'>
                <div className='w-60 h-96 flex flex-col items-center rounded-2xl bg-white mt-12 shadow-sm'>
                    asdasd
                </div>
                <div className='w-60 flex flex-col items-center rounded-full bg-white'>asd</div>
                <div className='w-60 flex flex-col items-center rounded-full bg-white mt-12'>asd</div>
            </div>
        </div> */}


        {/* FAQ */}
        <div ref={faqRef} className="w-full  flex flex-col items-center py-36 bg-white">
            <span className="text-3xl font-bold tracking-wider">FAQ</span>
            <div className='w-2/3 flex flex-col gap-4 py-2 mt-12'>
                {faqs.map((faq) => (
                    <AnsweredQuestion question={faq.question} answer={faq.answer}/>
                ))}
            </div>
        </div>

        

        <footer className="w-full flex flex-col items-center px-60 py-16  border-t border-gray bg-white">
            <div className='w-full flex justify-between items-start gap-12'>
                <Logo/>
                <div className='flex-grow'/>
                <div className='flex flex-col gap-4'>
                    <span className='text-lg font-bold text-black'>其它联系方式</span>
                    <span className=' text-black/80'>邮箱: rayliu@xoleyo.com</span>
                    <span className=' text-black/80'>微信: HeSiqian_20</span>
                </div>
                <div className='flex flex-col gap-4'>
                    <span className='text-lg font-bold text-black'>支持</span>
                    <button className=' text-black/80 hover:text-black/60' onClick={() => scrollToSection(introRef)}>功能介绍</button>
                    <button className=' text-black/80 hover:text-black/60' onClick={() => scrollToSection(usageRef)}>使用指南</button>
                    <button className=' text-black/80 hover:text-black/60' onClick={() => scrollToSection(analyseRef)}>竞品分析</button>
                    {/* <button className=' text-black/80 hover:text-black/60' onClick={() => scrollToSection(pricingRef)}>Pricing</button> */}
                    <button className=' text-black/80 hover:text-black/60' onClick={() => scrollToSection(faqRef)}>FAQ</button>
                </div>
            </div>
            <div className='w-full flex justify-start mt-12'>
                <span className='text-sm text-black/60'>© 2024 XoLeYo Inc. All rights reserved.</span>
            </div>
        </footer>

    </div>
  );
};

export default NewLandingPage;