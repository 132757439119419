import React from 'react';
import {useState, useRef} from 'react';
import WechatIcon from '../../public/assets/wechat.svg';
import VisibilityOnIcon from '../../public/assets/visibility_on.svg';
import VisibilityOffIcon from '../../public/assets/visibility_off.svg';
import CloseIcon from '../../public/assets/close.svg';
import { useRecoilState } from 'recoil';
import { loginWindowAtom, registerWindowAtom } from '../recoil/atoms/atoms';


const LoginWindow: React.FC = () => {
  // 定义一个状态来控制密码的显示状态
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginWindowStatus, setLoginWindowStatus] = useRecoilState(loginWindowAtom);
  const [registerWindowStatus, setRegisterWindowStatus] = useRecoilState(registerWindowAtom);
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const verificationCodeInputRef = useRef<HTMLInputElement>(null);

  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    input.value = input.value.replace(/\D/g, '');
    setIsPhoneValid(input.value.length === 11);
  };

  const handleLogin = async () => {
    
  }

  return (
    <div className="relative w-96 h-128 bg-white rounded-[16px] py-10 px-10 flex flex-col items-center z-[99]">
    <button
      onClick={() => {
        setLoginWindowStatus(false);
        setRegisterWindowStatus(false);
      }}
      className="hover:bg-gray-100 rounded-full p-2 absolute top-4 right-4"
    >
      <img src={CloseIcon} alt="close" className="size-6" />
    </button>

    <div className="flex items-center justify-center w-full gap-6 px-2 mt-4 text-xl">
      <button
        onClick={() => setIsPasswordLogin(true)}
        className={`${isPasswordLogin ? 'text-primary-color font-bold' : 'text-gray-300'}`}
      >
        密码登录
      </button>
      <div className="border-x h-6 " />
      <button
        onClick={() => setIsPasswordLogin(false)}
        className={`${!isPasswordLogin ? 'text-primary-color font-bold' : 'text-gray-300'}`}
      >
        手机登录
      </button>
    </div>

    {isPasswordLogin && (
    <div className="w-full bg-gray-100 rounded-full px-4 py-3 mt-12 flex items-center">
      <input
        ref={inputRef}
        className="focus:outline-none bg-transparent flex-grow"
        type="text"
        placeholder="用户名"
        />
    </div>
    )}
    {!isPasswordLogin && (
      <div className="w-full bg-gray-100 rounded-full px-4 py-3 mt-12 flex items-center gap-2">
        <span className="">+86</span>
        <div className="border-l h-6 border-gray-300" />
      <input
        ref={phoneInputRef}
        className="focus:outline-none bg-transparent"
        type="text"
        onChange={handlePhoneInputChange}
        maxLength={11}
        placeholder="手机号"
      />
    </div>
    )}

    {isPasswordLogin && (
    <div className="w-full bg-gray-100 rounded-full px-4 py-3 flex items-center mt-6">
      <input
        ref={passwordInputRef}
        className="focus:outline-none bg-transparent flex-grow"
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder="密码"
      />
      <button
        className="ml-2"
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
      >
        <img
          src={isPasswordVisible ? VisibilityOffIcon : VisibilityOnIcon}
          alt="visibility"
          className="size-6"
        />
        </button>
    </div>
    )}
    {!isPasswordLogin && (
      <div className="w-full bg-gray-100 rounded-full px-4 py-3 mt-6 flex items-center gap-2">
        <input
          ref={verificationCodeInputRef}
          className="focus:outline-none bg-transparent flex-grow"
          type="text"
          placeholder="验证码"
        />
        <button disabled={!isPhoneValid} className={` ${isPhoneValid ? 'text-primary-color' : 'text-gray-400'}`}>获取验证码</button>
      </div>
    )}

    {isPasswordLogin && (
      <div className="w-full flex justify-between mt-2">
        <button
        onClick={() => {
          setRegisterWindowStatus(true);
          setLoginWindowStatus(false);
        }}
        className="text-sm underline underline-offset-2 text-gray-400 hover:text-gray-500"
      >
        新用户?
      </button>
      <button className="text-sm underline underline-offset-2 text-gray-400 hover:text-gray-500">
          忘记密码
        </button>
      </div>
    )}
    {!isPasswordLogin && (
      <div className="w-full flex mt-2">
        <span className="text-sm text-gray-400">
          新用户会直接登录
        </span>
      </div>
    )}

    <button className="w-full bg-primary-color/90 hover:bg-primary-color text-white font-bold rounded-full py-2 mt-8">
      登录
    </button>

    <div className="flex items-center justify-center w-full gap-2 px-2 mt-6">
      <hr className="flex-grow border-gray-100 border" />
      <span className="text-sm text-gray-300">或者</span>
      <hr className="flex-grow border-gray-100 border" />
    </div>

    <div className="flex items-center justify-center w-full mt-4">
      <button className="bg-gray-100 rounded-full p-2">
        <img src={WechatIcon} alt="wechat" className="size-6" />
      </button>
    </div>
    </div>
  );
};

export default LoginWindow;
