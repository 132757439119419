import React, {useState, useMemo} from 'react';
import iconClose from '../../public/assets/icons/icon-close-ffffff.svg'
import {useRecoilState} from 'recoil';
import {commentWindowAtom} from '../recoil/atoms/atoms';
import iconFollow from '../../public/assets/icons/icon-follow-666666.svg';
import iconFollowHovered from '../../public/assets/icons/icon-follow-999999.svg'
import iconImage from '../../public/assets/icons/icon-image-666666.svg'
import iconImageHovered from '../../public/assets/icons/icon-image-999999.svg'
import iconEmoji from '../../public/assets/icons/icon-emoji-666666.svg'
import iconEmojiHovered from '../../public/assets/icons/icon-emoji-999999.svg'

export interface CommentWindowProps {
    postId: string;
    userId: string;
    commentsCount: number;
}

export interface CommentProps {
    userId: string;
    userName: string;
    avatarUrl: string;
    content: string;
    timestamp: string;
    imageUrl: string;
    likes: number;
    parentCommentId: string;
    childrenCount: number;
    responseCommentId: string;
    commentId: string;
    isLiked: boolean;
}

interface CommentItemProps {
    mainComment: CommentProps;
    responseComments: CommentProps[];
}

const CommentItem: React.FC<CommentItemProps> = (props) => {
    const [isFollowHovered, setIsFollowHovered] = useState(false);

    const [isResponseFolded, setIsResponseFolded] = useState(true);
    let hasResponse = props.responseComments.length > 0;
    
    // 调整时间格式
    const adjustTimeFormat = (timestamp: string) => {
        const now = new Date();
        const timestampDate = new Date(timestamp);
        const seconds = Math.floor((now.getTime() - timestampDate.getTime()) / 1000);
        const isSameDay = now.toDateString() === timestampDate.toDateString();
        if (seconds < 60) {
            return '刚刚';
        } else if (seconds < 3600) {
            return Math.floor(seconds / 60) + '分钟前';
        } else if (isSameDay) {
            return timestampDate.toLocaleTimeString('zh-CN', {hour: '2-digit', minute: '2-digit'});
        } else {
            return timestampDate.toLocaleString('zh-CN', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
        }
    }

    return (
        <div className='w-full h-auto flex flex-col py-2 px-4'>
            <div className='flex items-start gap-2'>
                <img src={props.mainComment.avatarUrl} alt="avatar"
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = 'https://via.placeholder.com/60';
                    }} className='size-9 rounded-full' />
                <div className='flex flex-col flex-grow items-start justify-center h-auto'>
                    <div className='flex w-full items-center justify-between'>
                        <span className='text-sm font-bold'>{props.mainComment.userName}</span>
                        <img src={isFollowHovered ? iconFollowHovered : iconFollow} alt="icon-follow" className='size-5' 
                            onMouseEnter={() => setIsFollowHovered(true)}
                            onMouseLeave={() => setIsFollowHovered(false)}
                        />
                    </div>
                    <div className='w-full my-1'>
                        <span className='text-sm'>{props.mainComment.content}</span>
                    </div>
                    <div className='flex w-full items-center justify-start gap-2'>
                        <span className='text-xs text-black/[.4]'>{adjustTimeFormat(props.mainComment.timestamp)}  · </span>
                        {props.mainComment.likes > 0 && <span className='text-xs text-black/[.4]'>{props.mainComment.likes} 次赞  · </span>}
                        <button onClick={() => setIsFollowHovered(true)} className='text-xs text-black/[.4] hover:text-black/[.6]'>回复</button>
                        <div className='flex-grow'></div>
                        <button className='text-lg text-black/[.4] w-5 hover:text-black/[.6]'>···</button>
                    </div>
                    { hasResponse && (
                        <div className='w-full flex flex-col items-start gap-4'>
                            <button className='text-xs text-black/[.4] hover:text-black/[.6]'
                                onClick={() => setIsResponseFolded(!isResponseFolded)}
                            >
                                —— {!isResponseFolded ? `查看回复(${props.responseComments.length})` : '收起回复'}
                            </button>
                            {isResponseFolded && props.responseComments.map((responseComment) => (
                                <div className='w-full h-auto flex flex-col'>
                                    <div className='flex items-start gap-2'>
                                        <img src={responseComment.avatarUrl} alt="avatar" onError={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.onerror = null;
                                            target.src = 'https://via.placeholder.com/60';
                                        }} className='size-6 rounded-full' />
                                        <div className='flex flex-col flex-grow items-start justify-center h-auto'>
                                            <div className='flex w-full items-start justify-between'>
                                                <span className='text-xs font-bold'>{responseComment.userName}</span>
                                                <img src={isFollowHovered ? iconFollowHovered : iconFollow} alt="icon-follow" className='size-4' 
                                                    onMouseEnter={() => setIsFollowHovered(true)}
                                                    onMouseLeave={() => setIsFollowHovered(false)}
                                                />
                                            </div>
                                            <div className='w-full'>
                                                <span className='text-xs'>{responseComment.content}</span>
                                            </div>
                                            <div className='flex w-full items-center justify-start gap-2'>
                                                <span className='text-xs text-black/[.4]'>{adjustTimeFormat(responseComment.timestamp)}  · </span>
                                                {responseComment.likes > 0 && <span className='text-xs text-black/[.4]'>{responseComment.likes} 次赞  · </span>}
                                                <button onClick={() => setIsFollowHovered(true)} className='text-xs text-black/[.4] hover:text-black/[.6]'>回复</button>
                                                <div className='flex-grow'/>
                                                <button className='text-md text-black/[.4] w-4 hover:text-black/[.6]'>···</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                
            </div>
        </div>
    )
}

const CommentWindow: React.FC<CommentWindowProps> = (props) => {

    const [commentWindowStatus, setCommentWindowStatus] = useRecoilState(commentWindowAtom);
    const [isTimeOrder, setIsTimeOrder] = useState(false);
    const [commentContent, setCommentContent] = useState('');
    const [isCommentEmpty, setIsCommentEmpty] = useState(true);
    
    const [isImageHovered, setIsImageHovered] = useState(false);
    const [isEmojiHovered, setIsEmojiHovered] = useState(false);
    const comments: CommentProps[] = [{
        userId: '1',
        userName: 'Rayno',
        avatarUrl: '',
        content: '这是第一条评论',
        timestamp: '2024-12-01 18:00:00',
        imageUrl: '',
        likes: 0,
        parentCommentId: '',
        responseCommentId: '',
        commentId: '1',
        isLiked: false,
        childrenCount: 0
    },{
        userId: '2',
        userName: 'Rayno',
        avatarUrl: '',
        content: '这是第二条评论',
        timestamp: '2024-12-01 18:00:00',
        imageUrl: '',
        likes: 95532,
        parentCommentId: '1',
        responseCommentId: '1',
        commentId: '2',
        isLiked: false,
        childrenCount: 0
    },{
        userId: '3',
        userName: 'Rayno',
        avatarUrl: '',
        content: '这是第三条评论',
        timestamp: '2024-12-01 19:00:00',
        imageUrl: '',
        likes: 125,
        parentCommentId: '1',
        responseCommentId: '1',
        commentId: '3',
        isLiked: false,
        childrenCount: 0
    }
];

    const processedComments = useMemo(() => {
        const mainComments = comments.filter((comment) => comment.parentCommentId === '');
        const responseComments = comments.filter((comment) => comment.parentCommentId !== '');
        
        const sortComments = (a: CommentProps, b: CommentProps) => {
            if (isTimeOrder) {
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            } else {
                return b.likes + b.childrenCount*5 - a.likes - a.childrenCount*5;
            }
        };

        const sortedMainComments = mainComments.sort(sortComments);
        return sortedMainComments.map((mainComment) => ({
            ...mainComment,
            responseComments: responseComments
            .filter((responseComment) => responseComment.parentCommentId === mainComment.commentId)
            .sort(sortComments)
        }));
    }, [comments, isTimeOrder]);

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const content = e.target.value;
        setCommentContent(content);
        setIsCommentEmpty(content === '');
    };

  return (
    <div className="relative w-[600px] h-[90vh] z-[99] flex flex-col bg-white rounded-md">
        <button onClick={() => setCommentWindowStatus(false)} className="absolute top-3 -right-10 w-8 h-8 hover:bg-gray-300 rounded-full">
            <img src={iconClose} alt="icon-close" className="size-8" />
        </button>
        <header className="flex w-full min-h-14 border-b border-gray-200 items-center justify-between px-4">
            <div className="flex items-center">
                <span className="text-sm mr-1">{props.commentsCount}</span>
                <span className="text-sm">条评论</span>
            </div>
            <div className="relative flex justify-between items-center bg-gray-100 rounded-full p-1">
                <div className={`absolute h-[85%] w-[52px] bg-white z-[9] rounded-full
                    transition-transform duration-200
                    ease-in-out shadow-sm
                    ${isTimeOrder ? 'translate-x-full' : 'translate-x-0'}
                    `}></div>
                <button
                    onClick={() => setIsTimeOrder(false)}
                    className={`text-center px-3 py-1 text-sm z-[10] ${
                    !isTimeOrder
                        ? ' text-black'
                        : 'text-gray-300 hover:text-gray-500'
                    }`}
                >
                    最热
                </button>
                <button
                    onClick={() => setIsTimeOrder(!isTimeOrder)}
                    className={`text-center px-3 py-1 text-sm z-[10] ${
                    isTimeOrder
                        ? 'text-black'
                        : 'text-gray-300 hover:text-gray-500'
                    }`}
                >
                    最新
                </button>
            </div>
        </header>

        <main className="w-full flex-1 min-h-0">
        <div className="w-full h-full overflow-y-auto">
            {processedComments.map((comment) => (
                <CommentItem key={comment.commentId} 
                mainComment={comment} 
                responseComments={comment.responseComments} />
            ))}
        </div>
        </main>

        <footer className="flex items-center justify-between w-full min-h-14 border-t border-gray-200 pl-2 pr-4">
            <button className='size-8'
                onMouseEnter={() => setIsEmojiHovered(true)}
                onMouseLeave={() => setIsEmojiHovered(false)}
            >
                <img src={isEmojiHovered ? iconEmojiHovered : iconEmoji} alt="icon-emoji" className='size-8' />
            </button>
            <button className='size-8 ml-1'
                onMouseEnter={() => setIsImageHovered(true)}
                onMouseLeave={() => setIsImageHovered(false)}
            >
                <img src={isImageHovered ? iconImageHovered : iconImage} alt="icon-image" className='size-8' />
            </button>
            <input className='flex-1 mx-2 bg-transparent hover:outline-none focus:outline-none' value={commentContent} onChange={handleCommentChange} placeholder='添加评论...'></input>
            <button className={`text-md rounded-full px-3 py-1 bg-primary-color text-white
                ${isCommentEmpty ? 'opacity-40 cursor-not-allowed' : 'hover:bg-primary-color/80'}`}>发布</button>
        </footer>
    </div>
  );
};

export default CommentWindow;