import axios, { AxiosInstance } from "axios";
import { EnumGrantType } from "./requestTypes";
import { useRecoilState } from "recoil";
import { userAtom } from "../recoil/atoms/atoms";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// 创建 axios 实例
const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 设置基础 URL
    timeout: 10000, // 设置请求超时时间 (10秒)
    // headers: {
    //   'Content-Type': 'application/json',
    //   // 添加其他通用的请求头
    // },
});


export const login = async (username:string, password:string) => {
    const response = await axiosInstance.post('/auth/login', {grantType: EnumGrantType.PASSWORD_GRANT, username, password});
    return response.data;
}

export const logout = async () => {
    const [cookies, removeCookie] = useCookies(['refreshToken','accessToken']);
    const [loginStatus, setLoginStatus] = useRecoilState(userAtom);
    const navigate = useNavigate();
    setLoginStatus({
        userId: '',
        loggedIn: false,
        userName: '',
        role: '',
        avatarUrl: '',
    });
    removeCookie('refreshToken', {path: '/'});
    removeCookie('accessToken', {path: '/'});
    navigate('/');
}

export const register = async (username:string, password:string) => {
    const response = await axiosInstance.post('/api/register', {username, password});
    return response.data;
}

export const getUserInfo = async (refreshToken: string) => {
    try {
        const response = await axiosInstance.post('/auth/login', {grantType: EnumGrantType.REFRESH_TOKEN, refreshToken});
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
}

export const validateLogginStatus = () => {
    const [loginStatus] = useRecoilState(userAtom);
    if(!loginStatus.loggedIn) {
        throw new Error('login required');
    }
}
