import React, {useEffect, useState, useCallback} from 'react';

import addImageIcon from '../../../public/assets/icons/icon-addImage-40px.svg'
import titleIcon from '../../../public/assets/icons/icon-title-40px.svg'
import boldIcon from '../../../public/assets/icons/icon-bold-40px.svg'
import italicIcon from '../../../public/assets/icons/icon-italic-40px.svg'
import underlineIcon from '../../../public/assets/icons/icon-underline-40px.svg'
import orderIcon from '../../../public/assets/icons/icon-order-40px.svg'
import dividerIcon from '../../../public/assets/icons/icon-divider-40px.svg'
import clearIcon from '../../../public/assets/icons/icon-clear-40px.svg'

import {createEditor, Editor, BaseEditor, Transforms, Text, Descendant, Point, Node, Range, Element, Path} from 'slate';
// 导入 Slate 组件和 React 插件。
import { Slate, Editable, withReact, ReactEditor, useSlate, useSelected, useFocused } from 'slate-react'
import { withHistory, HistoryEditor } from 'slate-history'

type CustomElement = { type: 'paragraph' | 'heading-one' | 'heading-two' | 'divider' | 'image'; 
    children: CustomText[];
    url?: string;
}
type CustomText = { text: string; bold?: boolean; italic?: boolean; underline?: boolean }

// 扩展 Slate 的类型定义
declare module 'slate' {
    interface CustomTypes {
      Editor: BaseEditor & ReactEditor & HistoryEditor
      Element: CustomElement
      Text: CustomText
    }
}

const withVoidNodes = (editor: Editor) => {
  const { isVoid } = editor
  editor.isVoid = (element) => {
    if (element.type === 'divider') return true
    else if (element.type === 'image') return true
    else return isVoid(element)
  }
  return editor
}

const NewEditor: React.FC = () => {

    const [dragOver, setDragOver] = useState(false);
    const [editor] = useState(() => withVoidNodes(withHistory(withReact(createEditor()))));
    

    const initialValue: Descendant[] = [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]

    // 判断是否有对应样式
    const isMarkActive = (editor: Editor, mark: keyof Omit<CustomText, 'text'>) => {
        const marks = Editor.marks(editor);
        return marks ? marks[mark] === true : false;
    }
    const toggleMark = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
        const isActive = isMarkActive(editor, format)
        if (isActive) {
          Editor.removeMark(editor, format)
        } else {
          Editor.addMark(editor, format, true)
        }
    }

    // const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
    //     const { selection } = editor
    //     if (!selection) return false
      
    //     const [match] = Array.from(
    //       Editor.nodes(editor, {
    //         at: Editor.unhangRange(editor, selection),
    //         match: n =>
    //           !Editor.isEditor(n) &&
    //           SlateElement.isElement(n) &&
    //           n[blockType] === format,
    //       })
    //     )
    //     return !!match
    // }

    // const toggleBlock = (editor: Editor, format: string) => {
    //     const isActive = isBlockActive(
    //       editor,
    //       format,
    //       TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    //     )
    //     const isList = LIST_TYPES.includes(format)
      
    //     Transforms.unwrapNodes(editor, {
    //       match: n =>
    //         !Editor.isEditor(n) &&
    //         SlateElement.isElement(n) &&
    //         LIST_TYPES.includes(n.type) &&
    //         !TEXT_ALIGN_TYPES.includes(format),
    //       split: true,
    //     })
    //     let newProperties: Partial<SlateElement>
    //     if (TEXT_ALIGN_TYPES.includes(format)) {
    //       newProperties = {
    //         align: isActive ? undefined : format,
    //       }
    //     } else {
    //       newProperties = {
    //         type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    //       }
    //     }
    //     Transforms.setNodes<SlateElement>(editor, newProperties)
      
    //     if (!isActive && isList) {
    //       const block = { type: format, children: [] }
    //       Transforms.wrapNodes(editor, block)
    //     }
    // }

    const MarkButton: React.FC<{ format: keyof Omit<CustomText, 'text'>, icon: string }> = ({ format, icon }) => {
        const editor = useSlate()
        return (
          <button
            className={`p-1 rounded-md hover:bg-gray-100/50 ${isMarkActive(editor, format) ? 'bg-gray-100/50' : ''}`}
            onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor, format)
            }}
          >
            <img src={icon} alt="title" className="size-8" />
          </button>
        )
    }

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
        if (!dragOver) setDragOver(true);
      }, [dragOver]);
      
      const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);
      }, [dragOver]);
    
      const renderElement = useCallback((props: any) => {
        const selected = useSelected()
        const focused = useFocused()

        switch (props.element.type) {
          case 'paragraph':
            return <p {...props.attributes}>{props.children}</p>;
          case 'heading-one':
            return <h1 {...props.attributes}>{props.children}</h1>;
          case 'heading-two':
            return <h2 {...props.attributes}>{props.children}</h2>;
          case 'divider':
            return (
              <div {...props.attributes} contentEditable={false} className={`w-full h-auto flex flex-col justify-center items-center my-4 border-2 ${selected && focused ? 'border-primary' : 'border-transparent'}`} 
              style={{
                lineHeight: 0,
              }}>
                <hr className="border-gray-200 w-1/2"/>
                {props.children}
              </div>
            );
          case 'image':
            return (
              <div {...props.attributes} contentEditable={false} className={`flex flex-col items-center w-full h-auto`}>
                <img src={props.element.url} className={`w-auto h-auto max-w-full border-2 ${selected && focused ? 'border-primary' : 'border-transparent'}`} />
                {props.children}
              </div>
            );
          // case 'block-quote':
          //   return <blockquote className="pl-4 text-gray-200 bg-gray-100/50" {...props.attributes}>{props.children}</blockquote>;
          default:
            return <p {...props.attributes}>{props.children}</p>;
        }
      }, []);
    
      const renderLeaf = useCallback((props: any) => {
        let {leaf, children, attributes} = props;
        if (leaf.bold) {
          children = <strong>{children}</strong>;
        }
        if (leaf.italic) {
          children = <em>{children}</em>;
        }
        if (leaf.underline) {
          children = <u>{children}</u>;
        }
        return <span {...attributes}>{children}</span>;
      }, []);
            
      const handleHotKeys = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        if (event.key === 'Backspace') {
          const { selection } = editor;
          if (selection && Range.isCollapsed(selection)) {
            const [node, path] = Editor.node(editor, selection);
            const start = Editor.start(editor, path);
            const isAtStart = Point.equals(selection.anchor, start);
            
            // 如果光标在开始位置
            if (isAtStart) {
              const [prevNode, prevPath] = Editor.previous(editor) || [];
              
              // 如果当前节点为空
              if (Node.string(node).length === 0) {
                event.preventDefault();
                
                // 检查是否是文档中唯一的节点
                const isOnlyNode = Editor.first(editor, [])[1].length === 1;
                
                if (!isOnlyNode) {
                  // 删除当前空节点
                  Transforms.removeNodes(editor);
                  
                  // 如果存在前一个节点，将光标移动到其末尾
                  if (prevPath) {
                    Transforms.select(editor, Editor.end(editor, prevPath));
                  }
                }
              }
              return;
            }
          }
        }
        else if (event.key === 'Delete') {
          const { selection } = editor;
          if (selection && Range.isCollapsed(selection)) {
            const [node, path] = Editor.node(editor, selection);
            const end = Editor.end(editor, path);
            const isAtEnd = Point.equals(selection.anchor, end);
            
            // 如果光标在结束位置
            if (isAtEnd) {
              let next;
              try {
                next = Editor.next(editor);
              } catch (error) {
                next = undefined;
              }
              const [nextNode, nextPath] = next || [];
              // 如果当前节点为空
              if (Node.string(node).length === 0) {
                event.preventDefault();
                // 检查是否是文档中最后一个节点
                const isLastNode = !nextPath;
                if (!isLastNode) {
                  try {
                    // 删除当前空节点
                    Transforms.removeNodes(editor);
                    
                    // 如果存在下一个节点，将光标移动到其开始位置
                    if (nextPath) {
                      const nextStart = Editor.start(editor, nextPath);
                      if (nextStart) {
                        Transforms.select(editor, nextStart);
                      }
                    }
                  } catch (error) {
                    console.error('Error handling delete:', error);
                  }
                }
              }
              return;
            }
          }
        }
        else if (event.key === 'Enter') {
          const { selection } = editor;
          if (selection) {
            const [node, path] = Editor.node(editor, selection);
            // 检查当前节点是否为 divider 或 image
            if (Element.isElement(node) && (node.type === 'divider' || node.type === 'image')) {
              // event.preventDefault();
              
              // 在当前节点后插入新的段落
              const nextPath = Path.next(path);
              Transforms.insertNodes(
                editor,
                { type: 'paragraph', children: [{ text: '' }] },
                { at: nextPath }
              );
              
              // 将光标移动到新插入的段落
              Transforms.select(editor, Editor.start(editor, nextPath));
              return;
            }
          }
        }

        if (!event.ctrlKey && !event.metaKey) return;
        console.log(event.key)
    
        switch (event.key) {
          case 'z': {
            event.preventDefault()
            editor.undo()
            break
          }
          case 'y': {
            event.preventDefault()
            editor.redo()
            break
          }
          case 'b': {
            event.preventDefault()
            toggleMark(editor, 'bold')
            break
          }
          // 添加斜体快捷键 Ctrl+i
          case 'i': {
            event.preventDefault()
            toggleMark(editor, 'italic')
            break
          }
          // 添加下划线快捷键 Ctrl+u
          case 'u': {
            event.preventDefault()
            toggleMark(editor, 'underline')
            break
          }
          case 'd': {
            event.preventDefault()
            Transforms.insertNodes(editor, {
              type: 'divider',
              children: [{ text: '' }],
            });
            // 插入一个新的空 paragraph 节点
            Transforms.insertNodes(editor, {
              type: 'paragraph',
              children: [{ text: '' }],
            });
            // 将光标移动到新插入的 paragraph 节点中
            Transforms.move(editor);
            break
          }

          case 'p': {
            console.log(editor.children)
            // event.preventDefault()
            // Transforms.insertNodes(editor, {
            //   type: 'block-quote',
            //   children: [{ text: '' }],
            // });
            break
          }
          // 删除整个文档 这里快捷键冲突了
          case 'Delete': {
            event.preventDefault()
            const pointStart = Editor.start(editor, []); // 文档的起点
            const pointEnd = Editor.end(editor, []); // 文档的终点
    
            // 删除整个范围的内容
            Transforms.delete(editor, {
              at: {
                anchor: pointStart,
                focus: pointEnd,
              },
            });
            break
          }
        }
      }, [editor]);
    
      const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const files = Array.from(event.dataTransfer.files)
        const images = files.filter(file => file.type.startsWith('image/'))
        setDragOver(false)
        if (images.length > 0) {
          const range = ReactEditor.findEventRange(editor, event);
          Transforms.select(editor, range);
          // 处理每个图片
        images.forEach(file => {
          const reader = new FileReader();
          console.log(file)
          reader.onload = (e) => {
            const url = e.target?.result as string;
            Transforms.insertNodes(editor, {
              type: 'image',
              url,
              children: [{ text: '' }],
            });
            // 在图片后插入段落
            Transforms.insertNodes(editor, {
                type: 'paragraph',
                children: [{ text: '' }],
              });
            };
            reader.readAsDataURL(file);
          });
        }
      }, [editor]);

    const isEditorEmpty = () => {
      // 获取编辑器中的所有内容
      const nodes = Array.from(Editor.nodes(editor, {
        at: [],
        match: n => Text.isText(n),
      }));

      // 检查是否只有一个空段落
      if (nodes.length === 0) return true;

      // 检查所有文本节点是否都为空
      return nodes.every(([node]) => Text.isText(node) && node.text.trim() === '');
    }
    
    return (
        <div className="w-full h-auto min-h-96 flex flex-col">
            <Slate editor={editor} initialValue={initialValue}>
              <header className="w-full h-auto pb-2 border-b border-gray-200 flex items-center gap-1">
                <MarkButton format="bold" icon={boldIcon} />
                <MarkButton format="italic" icon={italicIcon} />
                <MarkButton format="underline" icon={underlineIcon} />
              </header>
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={handleHotKeys}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                placeholder="请输入内容..."
                className={`rounded-md m-2 border-2 flex flex-col gap-2 ${dragOver ? 'border-dashed border-primary' : 'border-transparent'}`}
                style={{
                  minHeight: '384px', // 设置最小高度
                  height: 'auto',
                  width: 'calc(100% - 16px)',
                  outline: 'none',
                }}
              />
            </Slate>
            <footer className="w-full h-auto pt-2 bg-white flex items-center justify-end gap-2 border-t border-gray-200">
              <button className="px-4 py-2 rounded-md hover:bg-gray-100 hover:text-black/60">保存</button>
              <button className="px-4 py-2 rounded-md text-primary bg-primary/10 hover:bg-primary/20">发布</button>
            </footer>
        </div>
    )
}

export default NewEditor