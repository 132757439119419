import {atom} from 'recoil';

// 颜色主题
export const colorThemeAtom = atom({
  key: 'colorTheme',
  default: {
    colorTheme: 'light',
  },
});

// 用户信息
export const userAtom = atom({
  key: 'userAtom',
  default: {
    userId: '',
    loggedIn: false,
    userName: '用户名',
    role: '',
    avatarUrl: '',
  },
});

// 侧边栏状态
export const leftSideBarAtom = atom({
  key: 'leftSideBarAtom',
  default: 'home',
});

export const loginWindowAtom = atom({
  key: 'loginWindowAtom',
  default: false,
});

export const registerWindowAtom = atom({
  key: 'registerWindowAtom',
  default: false,
});

export const commentWindowAtom = atom({
  key: 'commentWindowAtom',
  default: false,
});
