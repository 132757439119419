import {BrowserRouter, Routes, Route} from 'react-router-dom';
import PageLayout from './pages/PageLayout';
import {RecoilRoot} from 'recoil';
import LandingPage from './pages/LandingPage';
import LoginWindow from './components/LoginWindow';
import NewLandingPage from './pages/NewLandingPage';
import WaitlistPage from './pages/WaitlistPage';
export const App = () => {

  return (

    // <ErrorBoundary>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/landing" element={<NewLandingPage />} />
          
          {/* 主页面布局 */}
          <Route path="/*" element={<PageLayout />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
    // </ErrorBoundary>
  );
};
