export enum EnumGrantType {
    PASSWORD_GRANT = 'password_grant',
    REFRESH_TOKEN = 'refresh_token',
    CLIENT_CREDENTIALS = 'client_credentials',
    AUTHORIZATION_CODE = 'authorization_code',
    DEVICE_CODE = 'device_code',
  }


export type UserInfoRequest = {
    grantType: EnumGrantType;
    refreshToken: string;
}

export type PasswordLoginRequest = {
  grantType: EnumGrantType.PASSWORD_GRANT;
  userName: string;
  password: string;
};

export type RegisterRequest = {
  userName: string;
  password: string;
};