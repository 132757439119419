import React from 'react';
import avatar from '../../public/assets/avatar.png';
import RightSideBar from '../components/common/RightSideBar';
import PrimaryBlock from '../components/UserHomePage/PrimaryBlock';
import SecondaryBlock from '../components/UserHomePage/SecondaryBlock';

const UserHomePage: React.FC = () => {
  return (
    <div className="flex h-full w-full">
      <main className="mx-12 px-2 flex-grow h-full">
        <div className="flex flex-col w-full">
          <div className="w-full h-24 bg-gray-200" />
          {/* 用户card */}
          <div className="flex flex-row w-full p-4">
            <div className="-mt-12 pr-4">
              <img
                src={avatar}
                className="border-2 border-white size-32 rounded-full"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xl font-bold">用户名</div>
              <div className="text-gray-500">个人签名</div>
              <div className="flex flex-row items-center text-gray-500">
                <span className="">IP 北京</span>
                <span className="">180</span>
                <span className="">78</span>
              </div>
            </div>
            <div className="flex-grow" />
            <div className="flex gap-4 items-start">
              <button className="bg-primary-color text-white px-4 py-2 rounded-full">
                关注
              </button>
              <button className="font-primary-color text-primary-color border-2 border-primary-color px-4 py-2 rounded-full hover:bg-primary-color/20">
                私信
              </button>
              {/* <button className='bg-primary-color text-white px-4 py-2 rounded-full'>

                            </button> */}
            </div>
          </div>

          <div className="flex w-full h-full gap-4">
            <PrimaryBlock />
            <SecondaryBlock />
          </div>
        </div>
      </main>
      <RightSideBar />
    </div>
  );
};

export default UserHomePage;
