import React, {useEffect, useState} from 'react';
import LeftSideBar from '../components/common/LeftSideBar';
import RightSideBar from '../components/common/RightSideBar';
import Post, {PostProps} from '../components/Post';
import SearchIcon from '../../public/assets/icons/search.svg';

const SearchBar = () => {
  return (
    <div className="flex items-center justify-center w-auto">
      <div className="w-96 h-10 rounded-full bg-gray-100 pr-2 pl-4 py-2 flex items-center justify-between">
        <input placeholder="搜索..." className="w-full h-full resize-none bg-transparent hover:outline-none focus:outline-none" />
        <button className="flex items-center justify-center hover:bg-gray-200 p-1 size-8 rounded-full">
          <img src={SearchIcon} alt="search" className="size-4"/>
        </button>
      </div>
    </div>
  );
};


const MainPage: React.FC = () => {

  const posts: PostProps[] = [{
    userName: '未云何龙',
    userId: '123',
    avatarUrl: '',
    content: '无论后世怎样评价斯大林的功过，但有一点是谁也无法否认的：斯大林是一位对国际政治有着极其深刻理解的伟大政治家。',
    timestamp: '2024-12-01 14:19:50',
    isFollowed: false,
    followers: 100,
    comments: 100,
  }];

  

  return (
    <div className='flex h-full w-full'>
      <div id='left-side-bar' className='min-w-72 h-full'/>
      <div className="flex flex-col h-full w-full">
        <div className="w-full py-4 px-12 flex fixed top-0 bg-white">
          <SearchBar />
        </div>
        <div className="w-full py-4 px-12 flex mt-[72px] gap-2">
          <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
            地域
          </button>
          <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
            年龄
          </button>
          <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
            时间段
          </button>
        </div>

        <div className="flex w-auto h-full">
          <main className="flex flex-col items-center w-auto h-full mr-20 ml-40">
            <div className='w-[700px] h-full px-2 flex flex-col gap-2'>
              {posts.map((item, index) => ( 
                <Post {...item} />
            ))}
          </div>
        </main>

      </div>
      </div>
      <div id='right-side-bar' className='min-w-72 h-full'>
        <div className="flex flex-col h-auto">
          <div className='flex flex-col items-start w-full h-autobg-gray-400'>
            <span className='text-2xl font-bold'>为你推荐</span>
            
          </div>

          <div className='flex items-center w-full bg-gray-200 gap-4'>
            <div className='flex flex-col items-center gap-2 ml-4 my-4'>
              <span className=''>新增关注</span>
              <span>102</span>
            </div>
            <div className='flex flex-col items-center gap-2 mr-4 my-4'>
              <span>新增浏览</span>
              <span>102</span>
            </div>
          </div>

          <div className='w-full flex flex-col items-start mt-2 gap-2'>
            <span className='text-xs text-gray-300'><button onClick={() => {window.open('https://xoleyo.com')}} className='hover:underline'>关于</button>·用户协议·隐私</span>
            <span className='text-xs text-gray-300'>© 2024 Rayno from Xoleyo</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
