import React from 'react';
import {SessionItemType} from './MsgClasses';

const SessionItem: React.FC<SessionItemType> = props => {
  return (
    <div className="w-full flex items-center gap-1 px-4 py-4 hover:bg-gray-100">
      <img src={props.avatar} alt="avatar" className="size-12 rounded-full" />
      <div className="flex flex-col justify-between">
        <span className="text-base font-bold">{props.name}</span>
        <span className="text-sm text-gray-500">{props.msg}</span>
      </div>
      <div className="flex-grow" />
      <span className="text-sm text-gray-500">{props.time}</span>
    </div>
  );
};

export default SessionItem;
