import React, {useState} from 'react';
import iconFollow from '../../public/assets/icons/icon-follow-666666.svg';
import iconFollowHover from '../../public/assets/icons/icon-follow-999999.svg';
import iconShare from '../../public/assets/icons/icon-share-40px.svg';
import iconMsg from '../../public/assets/icons/icon-comment-666666.svg';
import iconMsgHover from '../../public/assets/icons/icon-comment-999999.svg';
import iconMark from '../../public/assets/icons/icon-mark-666666.svg';
import iconMarkHover from '../../public/assets/icons/icon-mark-999999.svg';
import iconMore from '../../public/assets/more.svg';
import {marked} from 'marked';
import './Post.css';
import {useRecoilState} from 'recoil';  
import {commentWindowAtom} from '../recoil/atoms/atoms';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

export interface PostProps {
  userName: string;
  userId: string;
  avatarUrl: string;
  content: string; // markdown 格式内容
  followers: number;
  comments: number;
  timestamp: string;
  isFollowed: boolean;
}

const Post: React.FC<PostProps> = (props: PostProps) => {

  const navigate = useNavigate();
  const [commentWindowStatus, setCommentWindowStatus] = useRecoilState(commentWindowAtom);

  const [isFollowHovered, setIsFollowHovered] = useState(false);
  const [isMsgHovered, setIsMsgHovered] = useState(false);
  const [isMarkHovered, setIsMarkHovered] = useState(false);
  const [isFollowed, setIsFollowed] = useState(props.isFollowed);

  const convertMarkdownToHtml = (markdown: string) => {
    return marked(markdown);
  }

  // 跳转到用户主页
  const navigateToUserPage = () => {
    navigate(`/notification`);
  }

  // 改变关注状态

  // 获取评论

  // 添加评论

  // 变更收藏状态

  // 打开更多选项

  // 关注

  // 收藏

  // 调整时间格式
  const adjustTimeFormat = (timestamp: string) => {
    const now = new Date();
    const timestampDate = new Date(timestamp);
    const seconds = Math.floor((now.getTime() - timestampDate.getTime()) / 1000);
    const isSameDay = now.toDateString() === timestampDate.toDateString();
    if (seconds < 60) {
      return '刚刚';
    } else if (seconds < 3600) {
      return Math.floor(seconds / 60) + '分钟前';
    } else if (isSameDay) {
      return timestampDate.toLocaleTimeString('zh-CN', {hour: '2-digit', minute: '2-digit'});
    } else {
      return timestampDate.toLocaleString('zh-CN', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    }
  }

  return (
    <div className="w-full flex flex-col">
      <header className="flex w-full h-12 items-center">
        <button onClick={navigateToUserPage}>
          <img src={props.avatarUrl} alt="avatar"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src = 'https://via.placeholder.com/60';
            }} className="size-10 mr-2 rounded-full" />
        </button>
        <div className="flex flex-col items-start grow-0 w-auto">
          <button className="font-bold" onClick={navigateToUserPage}>{props.userName}</button>
          <span className="text-xs text-black/[.4]">{adjustTimeFormat(props.timestamp)}</span>
        </div>
        <div className="grow"/>
        <button className="size-8 hover:bg-gray-100 flex items-center justify-center rounded-full">
          <img src={iconMore} alt="more" className="w-8 h-8" />
        </button>
      </header>

      <main className="my-1">
        <div className='post' dangerouslySetInnerHTML={{__html: convertMarkdownToHtml(props.content)}} />
      </main>

      <footer className="flex items-center justify-between gap-2">
        <button
          onClick={() => setIsFollowed(!isFollowed)}
          onMouseEnter={() => setIsFollowHovered(true)}
          onMouseLeave={() => setIsFollowHovered(false)}
          data-tooltip-id="tooltip"
          data-tooltip-content={isFollowed ? '取消关注' : '关注'}
          className={`flex items-center ${isFollowed ? '' : ''} py-2 gap-1`}
        >
          <img src={isFollowHovered ? iconFollowHover : iconFollow} alt="star" className="size-6" />
          <span className="text-sm text-black/[.4]">{props.followers}</span>
        </button>
        <button
          onMouseEnter={() => setIsMsgHovered(true)}
          onMouseLeave={() => setIsMsgHovered(false)}
          onClick={() => setCommentWindowStatus(true)}
          className="flex items-center gap-1 py-2 ">
          <img src={isMsgHovered ? iconMsgHover : iconMsg} alt="star" className="size-6" />
          <span className="text-xs text-black/[.4]">{props.comments}</span>
        </button>
        <button
          onMouseEnter={() => setIsMarkHovered(true)}
          onMouseLeave={() => setIsMarkHovered(false)}
          className="flex items-center gap-1 py-2">
          <img src={isMarkHovered ? iconMarkHover : iconMark} alt="star" className="size-6" />
        </button>
        <div className="grow"/>
        <button className="flex items-center py-2">
          <img src={iconShare} alt="star" className="size-6" />
        </button>
      </footer>
    </div>
  );
};

export default Post;