import React from 'react';
import {Routes, Route} from 'react-router-dom';
import NewPost from '../Post';

const PrimaryBlock: React.FC = () => {
  return (
    <div className="flex flex-col w-3/4 h-full">
      <div className="flex w-full justify-center border-b">
        <button className="hover:bg-gray-100 h-16 w-20">帖子</button>
        <button className="hover:bg-gray-100 h-16 w-20">收藏</button>
        <button className="hover:bg-gray-100 h-16 w-20">我关注的</button>
      </div>
      <div className="flex w-full">
        <Routes>
          <Route
            path="/postlist/*"
            element={
              <div className="flex flex-col w-full">
                <NewPost
                  userName={'ray'}
                  timestamp={'2024-02-02'}
                  content={'asd'}
                  isFollowed={true}
                />
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default PrimaryBlock;
